<template>
  <div>
    <!--  头部咨询  -->
    <el-main class="main">
      <div class="main-box" id="description">
        <el-row>
          <el-col :span="24">
            <div class="main-description">产品与解决方案</div>
            <div class="industry-case-minSpan">PRODUCTS</div>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="space-around"
          style="width: 55%; margin: 60px auto"
        >
          <el-col
            :span="8"
            v-for="(item, index) in projectList"
            :key="index"
            class="main-description-box"
          >
            <div @click="jumpToDetail(index)">
              <div class="main-img">
                <img :src="item.imgUrl" />
              </div>
              <div class="main-title">
                {{ item.title }}
              </div>
              <div class="main-content">
                <span> {{ item.content }} </span>
              </div>
              <div class="main-more">查看更多</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="main-box parther-box" id="partner-program">
        <el-row>
          <el-col :span="24">
            <div class="main-description">合作伙伴计划</div>
            <div class="industry-case-minSpan">Partner Program</div>
          </el-col>
        </el-row>
        <div id="partner_id" class="partner-tab" style="margin-top: 60px">
          <el-tabs v-model="activeName" stretch>
            <el-tab-pane label="伙伴计划介绍" name="0" style="text-align:center">
              <img src="@/assets/partner.png" alt="" />
            </el-tab-pane>
            <el-tab-pane label="伙伴计划保障" name="1" style="text-align:center">
              <img src="@/assets/伙伴计划保障.png" alt="" />
            </el-tab-pane>
            <el-tab-pane label="伙伴权益" name="2" style="text-align:center">
              <img src="@/assets/伙伴权益.png" alt="" />
            </el-tab-pane>
            <el-tab-pane label="加入条件" name="3" style="text-align:center">
              <img src="@/assets/加入条件.png" alt="" />
            </el-tab-pane>
            <el-tab-pane label="加入流程" name="4" style="text-align:center">
              <img src="@/assets/加入流程.png" alt="" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="main-box industry-case" id="industry-case">
        <el-row>
          <el-col :span="24">
            <div class="main-description">行业案例</div>
            <div class="industry-case-minSpan">Industry case</div>
          </el-col>
        </el-row>

        <el-row type="flex" justify="center">
          <el-col :span="9">
            <div class="industry-case-content-left">
              <div class="industry-case-content-imgBox">
                <img :src="swiperList[currentIndex].image" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="industry-case-content-right">
              <swiper
                ref="mySwiper"
                :options="swiperOptions"
                @slideChange="onSlideChange"
              >
                <swiper-slide v-for="(item, index) in swiperList" :key="index">
                  <div class="swiper-box">
                    <h1>{{ item.title }}</h1>
                    <h4>{{ item.name }}</h4>
                    <span>
                      {{ item.content }}
                    </span>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="main-box aboutAs-box" id="about-us">
        <el-row>
          <el-col :span="24">
            <div class="main-aboutAs">关于我们</div>
            <div class="aboutAs-minSpan">ABOUT US</div>
          </el-col>
        </el-row>

        <el-row
          type="flex"
          justify="center"
          style="height: 470px; margin-top: 60px"
        >
          <div class="aboutAs-company-profile">
            <h1>公司介绍</h1>
            <p>
              北京高升数据系统有限公司成立于2015年，系高升控股股份有限公司（股票代码:000971.SZ）全资子公司。
            </p>
            <p>
              高升自2006年开始进入云基础服务领域，为广大政企单位及互联网客户提供从云到端的一站式综合云基础解决方案与服务集成，通过多年发展，高升围绕资源、应用、数据、服务四个方面，形成了以“云网”资源为基础，以“数据+应用”赋能，以“行业数字化服务”为价值输出的面向行业的“云+网+端”一体化服务能力。
            </p>
            <div class="main-more" @click="$router.push('/about')">
              查看更多
            </div>
          </div>
          <img width="30%" src="@/assets/home_location.png" alt="" />
        </el-row>
      </div>
    </el-main>
  </div>
</template>

<script>
import Swiper, { Pagination, Navigation } from "swiper";
import bus from "@/utils/bus";
Swiper.use([Pagination, Navigation]);
export default {
  name: "home",
  data() {
    return {
      activeName: 0,
      // 轮播图
      swiperOptions: {
        slidesPerView: 1, //显示个数
        parallax: true,
        loop: true,
        direction: "horizontal",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      //选中轮播图数据
      currentIndex: 0,
      // 轮播数据
      swiperList: [
        {
          title: "混合云平台",
          name: "上海市某科技园混合云平台",
          content:
            "该平台兼具国家经济技术开发区、国家高新技术产业开发区和国家综合保税区功能，规划面积10.7平方公里；是上海科创中心建设重要承载区和闵行“国家产城融合示范区”两大核心园区之一。实现了一朵“云”多租户资源隔离。园区内多公司自行管理。开箱即用，方便快捷。",
          image: require("../assets/临港浦江.png"),
        },
        {
          title: "园区云平台",
          name: "某省产业园区云平台",
          content:
            "为政府主管部门掌握重点产业及省内各个产业园区的发展状况提供可视化的数据支撑与信息服务，打造‘5+1’重点产业驾驶舱及全省产业园区详图;向园区管委会、园区企业提供精准招商、企业上云、合作交流等服务，打造全省产业园区应用服务生态。",
          image: require("../assets/四川产业园区.png"),
        },
        {
          title: "数字城市",
          name: "数字智慧管控平台",
          content:
            "该平台响应“国家治理体系和治理能力现代化”建设要求，以建设现代化国际化先进城市为目标，由中共杭州市某区人民政府共同推动的数字智慧管控平台项目，实现一屏全查、一网统管全场景大数据处理技术架构，为数字临安治理体系和治理能力现代化赋能。",
          image: require("../assets/杭州临安.png"),
        },
      ],
      // 产品与解决方案
      projectList: [
        {
          imgUrl: require("@/assets/Cloud.png"),
          title: "云产品",
          content:
            "以专业成熟的一站式行业云解决方案，帮助用户快速应用云计算。灵活部署的方式，助您一键上云。",
          // route: 'cloud',
          route: "cloud",
          index: 1,
        },
        {
          imgUrl: require("@/assets/Digital.png"),
          title: "大数据可视化平台",
          content:
            "运用人工智能、大数据等技术，与业务模型相融合，可以实现面向特定领域的可视化分析。",
          route: "digital",
          index: 2,
        },
        {
          imgUrl: require("@/assets/Park.png"),
          title: "园区解决方案",
          content:
            "帮助政府园区进行数字化转型升级，提升园区智能管理水平和企业生产效能。",
          route: "park",
          index: 3,
        },
      ],
      // 合作伙伴计划
      partner: [
        {
          title: "伙伴计划介绍",
          cotent: "",
          class: "partner partherStyle",
        },
        {
          title: "伙伴计划保障",
          cotent: "",
          class: "partner",
        },
        {
          title: "伙伴权益",
          cotent: "",
          class: "partner",
        },
        {
          title: "加入条件",
          cotent: "",
          class: "partner",
        },
        {
          title: "加入流程",
          cotent: "",
          class: "partner",
        },
      ],
      partnerIdx: 0,
    };
  },
  mounted() {
    bus.$on("message", (e) => {
      this.activeName = JSON.stringify(e);
      this.$el
        .querySelector("#partner-program")
        .scrollIntoView({ behavior: "smooth" });
    });
    bus.$on("on-jump-description", () => {
      this.$el
        .querySelector("#description")
        .scrollIntoView({ behavior: "smooth" });
    });

    bus.$on("on-jump-case-module", (e) => {
      this.currentIndex = e;
      this.$refs.mySwiper.$swiper.slideTo(this.currentIndex + 1, 0, true);
      this.$el
        .querySelector("#industry-case")
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  methods: {
    jumpToDetail(index) {
      console.log("-------------------111");
      let route = this.projectList[index].route;
      this.$router.push(route);
    },
    onSlideChange(swiper) {
      console.log("slide change");

      // console.log(e.target);
      // console.log(this.currentIndex);
      this.currentIndex = swiper.realIndex;
      console.log(swiper);
    },
    changeMask(data, idx, e) {
      console.log(data, idx, e);
      // if (e.type === 'mouseover') {
      //   console.log(this.partnerIdx, idx, !this.partnerIdx == idx);
      //   this.partner[idx].class = 'partner partherStyle'
      //   if (this.partnerIdx == idx) {
      //     this.partner[this.partnerIdx].class = 'partner'
      //   }
      // } else {
      //   // this.partner[idx].class = 'partner'
      //   this.partnerIdx = idx
      // }
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
};
</script>

<style>
.el-tabs__nav-wrap::after {
  height: 0;
}
.el-tabs__header {
  margin-bottom: 30px;
}
::v-deep .el-tabs__nav-scroll {
  width: 50%;
  margin: 0 auto;
}
/*  轮播图翻页按钮  */
.industry-case-content-right /deep/ .swiper-pagination {
  bottom: 10px !important;
  left: 0px !important;
  top: 80% !important;
  width: auto !important;
}
</style>
<style scoped lang="scss">
.login-wrapper {
  display: flex;
  width: 100%;
  min-height: 560px;
  height: calc(100% - 60px);
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.main {
  max-width: 1920px;
  margin: 0 auto;

  // main主体部分
  .main {
    padding: 0;

    // 内容盒子
    .main-box {
      padding: 70px 0;
      .industry-case-minSpan {
        font-family: SourceHanSansCN-Normal;
        font-size: 20px;
        color: #e7e7e7;
        letter-spacing: 3.1px;
        text-align: center;
      }
    }

    // 产品与解决方案
    .main-description {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      font-family: SourceHanSansCN-Bold;
    }
    .main-description:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      width: 12px;
      margin-right: 40px;
      background-color: #2965ed;
    }
    .main-description:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      width: 12px;
      margin-left: 40px;
      background-color: #2965ed;
    }
    .main-description-box {
      min-width: 260px;
      height: 484px;
      padding: 40px;
      border: 2px solid #ffffff;
      margin-right: 60px;
    }
    .main-description-box:hover {
      background: #ffffff;
      border: 2px solid #2c68ee;
      box-shadow: 1px 1px 43px 0 rgba(21, 113, 179, 0.07);
    }
    .main-description-box:last-child {
      margin-right: 0;
    }
    .main-img {
      img {
        height: 90px;
        width: 90px;
        display: inherit;
        margin: 0 auto;
      }
    }
    .main-title {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      color: #1c1c1c;
      margin: 40px 0;
      font-family: SourceHanSansCN-Bold;
    }
    .main-content {
      height: 120px;
      font-family: SourceHanSansCN-Normal;
      font-size: 16px;
      color: #808080;
      letter-spacing: 0;
      line-height: 30px;
    }
    .main-more {
      // width: 64px;
      height: 16px;
      font-family: SourceHanSansCN-Normal;
      font-size: 16px;
      color: #2c68ee;
      margin-top: 15px;
      cursor: pointer;
    }
    .main-more:after {
      content: "";
      display: inline-block;
      // vertical-align: middle;
      height: 16px;
      width: 20px;
      margin-left: 10px;
      // position: absolute;
      background-image: url("~@/assets/more.png");
    }

    // 合作伙伴计划
    .parther-box {
      background: rgb(245, 247, 253);
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-evenly;
      .partner-tab {
        width: 65%;
        height: 600px;
        padding: 0 150px;
        img {
          max-width: 100%;
        }
      }
    }
    .partner {
      font-family: SourceHanSansCN-Norma;
      font-size: 18px;
      color: #808080;
      text-align: center;
      margin-top: 25px;
      padding: 5px;
      border-bottom: 2px solid rgb(245, 247, 253);
    }
    .partherStyle {
      color: #1c1c1c;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 2px solid #2c68ee;
    }
    .partner:hover {
      color: #1c1c1c;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 2px solid #2c68ee;
    }

    .partner-content {
      margin-top: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 行业案例
    .industry-case {
      .industry-case-minSpan {
        font-family: SourceHanSansCN-Normal;
        font-size: 20px;
        color: #e7e7e7;
        letter-spacing: 3.1px;
        text-align: center;
      }
      .industry-case-content-left {
        //width: 751px;
        height: 510px;
        background: url("~@/assets/computer.png") no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .industry-case-content-imgBox {
          height: 70%;
          width: 76%;
          overflow: hidden;
          overflow-y: auto;
          img {
            width: 100%;
          }
        }
        .industry-case-content-imgBox::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        .industry-case-content-imgBox::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #808080;
        }
        .industry-case-content-imgBox::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          background: #ffffff;
        }
      }
      .industry-case-content-right {
        height: 100%;
        .swiper-container {
          width: 100%;
          height: 100%;
          .swiper-pagination {
            width: auto;
            bottom: 20%;
            .swiper-pagination-bullet-active {
              width: 40px;
              height: 10px;
              background: #2c68ee;
            }
            .swiper-pagination-bullet {
              width: 40px;
              height: 10px;
              opacity: 0.2;
              background: #2c68ee;
            }
          }
          .swiper-box {
            width: 65%;
            height: 385px;
            overflow: hidden;
            overflow-y: auto;
            h1 {
              padding-top: 90px;
            }
            h4 {
              padding: 20px 0;
            }
            span {
              font-family: SourceHanSansCN-Normal;
              font-size: 16px;
              color: #808080;
              letter-spacing: 0;
              line-height: 40px;
            }
          }
          .swiper-box::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }
          .swiper-box::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #808080;
          }
          .swiper-box::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ffffff;
          }
        }

        .swiper-pagination-custom {
          bottom: 5%;
          left: 0;
          width: 100%;
          height: 20px;
        }
        .swiper-pagination-customs {
          display: block;
          width: 40px;
          height: 10px;
          background: #2c68ee;
          margin-left: 2vw;
        }
        /*自定义分页器激活时的样式表现*/
        .swiper-pagination-customs-active {
          display: block;
          width: 40px;
          height: 10px;
          background: #2c68ee;
          margin-left: 2vw;
        }
      }
    }

    // 关于我们
    .aboutAs-box {
      background-image: linear-gradient(159deg, #8fc6fe 0%, #2965ed 100%);
    }
    .main-aboutAs {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
    }
    .main-aboutAs:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      width: 12px;
      margin-right: 40px;
      background-color: #ffffff;
    }
    .main-aboutAs:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      width: 12px;
      margin-left: 40px;
      background-color: #ffffff;
    }
    .aboutAs-content {
      // height: 400px;
      height: auto;
      //background: url("~@/assets/Location.png") no-repeat 0% 40%;
      overflow: auto;
      // margin-top: 30px;
      // img {
      // transform: translate(0%, -20%);
      // transform-origin: (0, 0);
      // }
    }
    .aboutAs-minSpan {
      font-family: SourceHanSansCN-Normal;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 3.1px;
      text-align: center;
      margin: 10px 0;
    }
    .aboutAs-company-profile::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    .aboutAs-company-profile::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #808080;
    }
    .aboutAs-company-profile::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
    .aboutAs-company-profile {
      // position: absolute;
      // top: 30px;
      width: 30%;
      // height: 320px;
      background: #ffffff;
      padding: 40px;
      overflow: hidden;
      overflow-y: auto;
      h1 {
        margin-bottom: 10px;
      }
      p {
        font-family: SourceHanSansCN-Normal;
        font-size: 16px;
        color: #808080;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }
}
</style>
